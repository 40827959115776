import { Button, makeStyles, Menu, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import { connect } from "react-redux";
import { hasRoutePermission } from "shared/utils";

// import Table from "./Table";
const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.down("lg")]: {
      "& .MuiButton-root": {
        padding: "6px 8px",
        fontSize: "10px",
      },
      //   "& button": {
      //     backgroundColor: "grey",
      //   },
    },
  },
}));

function ResponsiveMenu({
  role,
  menuItems = [],
  buttonProps = {},
  buttonText = "",
  disabled = false,
  onSelect = () => {},
  route = "",
  permission,
  ...props
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  if (route && !hasRoutePermission(route, role, permission)) {
    return null;
  }

  let items = menuItems;
  if (role["id"] != 1) {
    if (menuItems.length) {
      items = menuItems.filter((values, index) => {
        return hasRoutePermission(values.route, role, values.permission);
      });
    }
  }

  return items.length ? (
    <div className={classes.button}>
      <Button
        disabled={disabled}
        variant="contained"
        className="shadow-none"
        color="primary"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        endIcon={props.endIcon ? props.endIcon : null}
        startIcon={props.startIcon ? props.startIcon : null}
        {...buttonProps}
      >
        {buttonText}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {items.map(({ text, id = null }, key) => {
          return (
            <MenuItem
              onClick={() => {
                onSelect(id || key);
                setAnchorEl(null);
              }}
              key={id || key}
            >
              {text}
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  ) : null;
}

const mapStateToProps = ({ role }) => ({
  role,
});

export default connect(mapStateToProps)(ResponsiveMenu);
