import React, { useState, useEffect } from 'react';
import { getNachSummary } from 'shared/api/nach';
import { connect } from 'react-redux'
import { convertKeyIntoNormal } from '../../../shared/utils';
import { CardHeader, TableBody, Table, TableRow, Card, CardContent } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import TableBodyCell from 'components/table/TableBodyCell';
import NoDataFound from 'shared/components/no-data-found';

const NachSummary = (props) => {
  const [nachSummary, setnachSummary] = useState(null)
  const [loaded, setloaded] = useState(false);

  useEffect(() => {
    getNachSummary().then(res => {
      setloaded(true);
      if (res && res.hasOwnProperty('data') && res.data.hasOwnProperty('summary')) {
        const { summary } = res.data
        setnachSummary(summary);
      }
    }).catch((err)=>setloaded(true))
  }, [])
  return (
    <Card>
      <CardHeader component="span" title={<span className="text-sm">Summary <span className="text-xs">(Showing Data of Current Month)</span> </span>}
      ></CardHeader>
      <CardContent>
        {loaded && nachSummary ?
          <Table>
            <TableBody>
              <TableRow>
                {Object.keys(nachSummary).map((key,index) => <TableBodyCell key={`${index}-${key}`}>{convertKeyIntoNormal(key)}</TableBodyCell>)}
              </TableRow>
            </TableBody>
            
            <TableBody>
              <TableRow>
                {Object.keys(nachSummary).map((key,index) => <TableBodyCell key={`${index}-${key}`}>{nachSummary[key]}</TableBodyCell>)}
              </TableRow>
            </TableBody>
          </Table> : !loaded && <Skeleton variant="rect" height={60} />}
          {loaded && !nachSummary && <NoDataFound/>}
      </CardContent>
    </Card>
  )
}
const mapStateToProps = (state) => {
  return state
}
const mapDispatchToProps = {

}
export default React.memo(connect(mapStateToProps, mapDispatchToProps)(NachSummary))
