import React, { useState } from "react";

// Navigation
import { NavLink } from "react-router-dom";

// Material UI
import Button from "@material-ui/core/Button";
import Drawer from "@material-ui/core/Drawer";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
// redux
import { connect } from "react-redux";
import { signOut } from "redux/actions/auth";
import { Avatar, CircularProgress, ListItemIcon } from "@material-ui/core";

import AvtarImage from "assets/images/no-user-img.png";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import Colors from "shared/color";
import { getLoginRoleDetails } from "shared/api";

const AUDITOR_Role_Id = process.env.REACT_APP_NAME === "DEV" ? [65,70, 110] : [63,69, 73];

const RightSideDrawer = ({ signOut, user, role }) => {
  const [open, setOpen] = React.useState(false);
  const [loading, setloading] = useState(false);
  const handleToggle = () => {
    // closeSideDrawer();
    setOpen((prevOpen) => !prevOpen);
  };
  const RefreshRoles = async () => {
    await setloading(true);
    let roles = await getLoginRoleDetails(user["role_id"], user["token"]);
    await setloading(false);
    localStorage.setItem("role", JSON.stringify(roles["data"]["data"]));
  };

  return (
    <>
      <div className="float-right">
        <Button onClick={handleToggle} color="inherit">
          <div style={{ textTransform: "capitalize", paddingTop: "0.8125rem" }}>
            <p style={{ color: "#000" }}>{user.name}</p>
            <p
              style={{
                fontSize: "0.75rem",
                color: Colors["purple_400"],
                marginTop: "-5px",
              }}
            >
              {user.role}
            </p>
          </div>
          <KeyboardArrowDownIcon className="px-1 opacity-50" />
          <Avatar
            className="float-right small"
            alt="Remy Sharp"
            src={AvtarImage}
          />
        </Button>
      </div>
      <Drawer anchor="right" open={open} onClose={handleToggle}>
        <div style={{ width: 250 }}>
          <ListItem button component={NavLink} to="/">
            <ListItemText primary="My Profile" />
          </ListItem>
          <ListItem button onClick={(_) => RefreshRoles()}>
            {loading && (
              <ListItemIcon>
                <CircularProgress size={24} color="primary" />
              </ListItemIcon>
            )}
            <ListItemText primary="Refresh Permission">Demo</ListItemText>
          </ListItem>
          {!AUDITOR_Role_Id.includes(role.id) && (
            <ListItem
              button
              component={NavLink}
              to="/admin/subuser_management/pendinglist"
            >
              <ListItemText primary="Pending Requests" />
            </ListItem>
          )}
          <ListItem button onClick={(_) => signOut()}>
            <ListItemText primary="Logout" />
          </ListItem>
          <span className="absolute bottom-0 pl-2">
            Version : {process.env.REACT_APP_VERSION}
          </span>
        </div>
      </Drawer>
    </>
  );
};

const mapStateToProps = ({ auth, role }) => ({
  user: auth.user,
  role,
});

const mapDispatchToProps = {
  signOut: signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(RightSideDrawer);
