import React, { useEffect } from "react";

// redux
import { connect } from "react-redux";
import { signOut } from "redux/actions/auth";

// axios
import axiosinstance from "./index";

// utils
import { toastError } from "shared/utils";

const errorHandler = (req) => {
  if (req) {
    if (req.message) {
      toastError(req.message);
    } else {
      toastError("Something went wrong! please try again later.");
    }
  } else {
    toastError("Something went wrong! please try again later.");
  }
};

const withAxiosWrapper = (WrappedComponent) => {
  const Handler = (props) => {
    const { auth } = props;
    const reqInterceptor = axiosinstance.interceptors.request.use((req) => {
      const token = auth && auth["user"] && auth["user"]["token"];

      req.headers["Content-Type"] = req.headers.isFormData
        ? "multipart/form-data"
        : "application/json";

      let authorization = "";
      if (token) {
        authorization = `Bearer ${token}`;
      }

      if (req.headers.hasOwnProperty("isFormData"))
        delete req.headers["isFormData"];

      req.headers["Authorization"] = authorization;

      return req;
    });

    const resInterceptor = axiosinstance.interceptors.response.use(
      (res) => {
        if (res && res.data) {
          return Promise.resolve(res.data);
        }

        // errorHandler(res.data);
        return Promise.reject(res.data);
      },
      (err) => {
        if (err.response && err.response.status === 401) {
          props.signOut();
        }
        errorHandler(err.response && err.response.data);
        return Promise.reject(err);
      }
    );

    useEffect(() => {
      return () => {
        axiosinstance.interceptors.request.eject(reqInterceptor);
        axiosinstance.interceptors.response.eject(resInterceptor);
      };
    }, [reqInterceptor, resInterceptor]);

    return <WrappedComponent {...props} />;
  };

  const mapStateToProps = ({ auth }) => ({
    auth,
  });

  const mapDispatchToProps = {
    signOut: signOut,
  };

  const HandlerConnector = connect(
    mapStateToProps,
    mapDispatchToProps
  )(Handler);

  return HandlerConnector;
};

export default withAxiosWrapper;
