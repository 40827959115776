import { BootstrapBadge } from 'components/ui/BootstrapBadge';
import KisshtLink from 'components/ui/KisshtLink';
import React,{useMemo} from 'react';
import { NACH_API } from 'shared/api/nach';
import Colors from 'shared/color';
import { PERMISSIONS, ROUTES_VAR, ROUTE_NAME } from 'shared/constants';
import { readableFormatDate } from 'shared/utils';
import NachPresentation from '..';

const LotuspayNach = (props) =>{
    const statusOptions = {
      NACH_REQUESTED: "Nach Requested",
      NACH_PROCESSED: "Nach Processed",
    };
    const searchOptions = {
      payment_reference_number: "Payment Reference Number",
      user_reference_number: "User Reference Number",
      mandate_reference_number: 'Mandate Reference Number'
    };
    const columns = React.useMemo(() => [
        {
          Header: "Payment Reference No.",
          accessor: "payment_reference_number",
          sortable: true,
          width: 150,
          Cell: (cell) => {
            return (
              <KisshtLink
                to={`${ROUTES_VAR.payments}/${cell.value}`}
                permission={PERMISSIONS.payment.details}
                route={ROUTE_NAME.payment}
                style={{ color: Colors.purple_400 }}
              >
                {cell.value}
              </KisshtLink>
            );
          },
          filterable: false,
        },
        {
          Header: "Payment Partner Id",
          accessor: "partner_payment_id",
          sortable: false,
          filterable: false
        },
    
        {
          Header: "User Reference No.",
          accessor: "user_reference_number",
          filterable: false,
          sortable: false,
          width: 150,
          Cell: (cell) =>
            <KisshtLink
              to={`${ROUTES_VAR.users}/${cell.value}`}
              permission={PERMISSIONS.user.details}
              route={ROUTE_NAME.user}
              style={{ color: Colors.purple_400 }}
            >
              {cell.value}
            </KisshtLink>
        },
        {
          Header: "Mandate ref No",
          accessor: "mandate_reference_number",
          filterable: false,
          sortable: false,
        },
    
        {
          Header: "Receiving Bank",
          accessor: "receiving_bank",
          filterable: false,
          sortable: false,
        },
        {
          Header: "Status",
          accessor: "status",
          filterable: false,
          width: 150,
          Cell: (cell) => {
            return <BootstrapBadge text={cell.value} />;
          },
        },
        {
          Header: "Is Bounce",
          accessor: "is_bounce",
          filterable: false,
          Cell: (cell) => {
            return <BootstrapBadge text={Number(cell.value) ? "YES" : "NO"} />;
          },
        },
    
        {
          Header: "Nach Presentation Date",
          accessor: "nach_presentation_date",
          filterable: false,
          sortable: false,
          Cell: (cell) => {
            return readableFormatDate(cell.value);
          },
        },
        {
          Header: "Created Date",
          accessor: "created_at",
          Cell: (cell) => {
            return readableFormatDate(cell.value);
          },
          filterable: false,
        },
      ]);
    return (
        <NachPresentation 
        params={props.params} 
        updateQueryParams={props.updateQueryParams}
        delteQueryParams={props.delteQueryParams}
        history={props.history}
        title={"Lotuspay Nach"} 
        showSummary={true}
        columns={columns}
        url={NACH_API.nachPayment}
        statusOptions={statusOptions}
        searchOptions={searchOptions}
        />
    )
}

export default LotuspayNach;