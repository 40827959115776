import React from "react";

// React router
import { Route, Redirect } from "react-router-dom";

// Routes const
import { ROUTES_VAR } from "shared/constants";

const PrivateRoute = ({ comp: Component, loggedInToken, ...rest }) => {
  const isLogin = !!loggedInToken;

  return isLogin ? (
    <Route {...rest} render={(props) => <Component {...props} />} />
  ) : (
    <Redirect
      to={{
        pathname: ROUTES_VAR.sign,
        state: { from: rest.location },
      }}
    />
  );
};

export default PrivateRoute;
