import React, { useRef } from "react";
import { makeStyles } from "@material-ui/core";
import DateRangeIcon from "@material-ui/icons/DateRange";
import TextField from "@material-ui/core/TextField";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& input[type='date']": {
      "&::-webkit-calendar-picker-indicator": {
        opacity: 0,
        zIndex: 1,
      },
    },
    "& input[type='datetime-local']": {
      cursor: "pointer",
      "&::-webkit-calendar-picker-indicator": {
        opacity: 0,
        zIndex: 1,
        position: "absolute",
        right: "1rem",
        width: "70%",
        height: "80%",
        cursor: "pointer",
      },
    },

    "& input": {
      border: "1px solid #E2E5EC",
    },
    "& .MuiInputAdornment-root": {
      position: "absolute",
      right: "1.4rem",
    },
    "& .Mui-error": {
      "& input": {
        borderColor: "#f44336",
      },
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        display: "none",
        border: "1px solid #E2E5EC",
        borderRadius: "3px",
      },
      "&:hover fieldset": {
        borderColor: "#E2E5EC",
      },
    },
  },
}));

const CustomInput = ({
  style,
  textFieldStyle={},
  type = "text",
  inputProps={},
  onKeyPress = false,
  InputProps = {},
  ...props
}) => {
  const classes = useStyles();
  const { touched, errors, name, helperText,autoNofocus } = props;
  let textInput = useRef(null);
  const styles = {
    fontSize: "0.8125rem",
    padding: " 0.6875rem",
    color: "#898C98",
    height: "0.75rem",
    backgroundColor: "#fff",
    ...style,
  };
  return (
    <TextField
      autoFocus={autoNofocus?false:true}
      style={textFieldStyle}
      // style={{ ...textFiledStyle }}
      className={classes.textField}
      id="outlined-secondary"
      variant="outlined"
      size="small"
      type={type}
      inputRef={textInput}
      onClick={() => textInput.current.focus()}
      onKeyPress={(e) => onKeyPress && e.key == "Enter" && onKeyPress(e)}

      InputProps={InputProps}
      inputProps={{
        autoComplete: "new-password",
        style: {
          ...styles,
        },
        form: {
          autocomplete: "off",
        },
        classes: {},
        ...inputProps,
      }}
      id="outlined-error-helper-text"


      helperText={
        errors && errors[name] && touched[name] ? errors[name] : helperText
      }
      error={errors && errors[name] && touched[name]}

      {...props}
    />
  );
};

export default CustomInput;
