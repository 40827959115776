import React from "react";

// Material UI
import { makeStyles } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";

import Colors from "shared/color";

// Component
import { DrawerSize } from "shared/constants";

// Custom style hook
const useStyles = makeStyles((theme) => ({
  header: {
    boxShadow: `0px 3px 6px ${Colors["LightPurpleShadow"]}`,
    "& > div:first-child": {
      minHeight: 129,
    },
  },
  appBar: {
    marginLeft: DrawerSize["closeWidth"],
    width: `calc(100% - ${DrawerSize["closeWidth"]}px)`,
    // left: `calc(100% - calc(100% - 90px))`,
    minWidth: "400px",
    // zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    // marginLeft: drawerWidth,
    width: `calc(100% - ${DrawerSize["width"]}px)`,

    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));

const Header = ({ open =true, children }) => {
  const classes = useStyles();

  return (
    <AppBar
    id="ScreenHeader"
      style={{ boxShadow: "none" }}
      position="fixed"
      className={`${
        !open ? classes.appBar : classes.appBarShift
      } bg-white text-gray-700  `}
    >
      {children}
    </AppBar>
  );
};

export default Header;
