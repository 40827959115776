import {
    ROLE
  } from "redux/actions/role/roleTypes";
  
  const initState =  localStorage.getItem("role") ? JSON.parse(localStorage.getItem("role"))  : {},

  
roleReducer = (state = initState, { type, payload }) => {

    switch (type) {
      case ROLE:
        return {
          ...state,...payload
        };
  
      default:
        return state;
    }
  };
  
  export default roleReducer;
  