import React, { useState, useEffect } from "react";
import {
  DialogContent,
  Card,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  CardContent,
} from "@material-ui/core";
import Colors from "shared/color";
import { toastSuccess,toastError} from "shared/utils";
import { fileUpload } from "shared/api/nach";
import Spin from "components/ui/Spin";
import { DropzoneArea} from "material-ui-dropzone";



const LotuspayPreprocessing = (props) => {
  const samples =  {
    PAYMENTS:"payment",
    LOANS:'loan'
  }
  const { handleClose, module,reason,...rest} = props
  const [spinning, setSpinning] = useState(false);
  const [file, setFile] = useState([]);
  const redirect = () => {
    props.history.push(`/uploads?selected=${reason}`);
  };

  const fileUploadHandler = () => {
    if (file.length) {
      var formData = new FormData();
      formData.append("file", file[0]);
      formData.append("reason", reason);
      formData.append("module", module);
      setSpinning(true);
      fileUpload(formData).then((res) => {
        setSpinning(false);
        if (res.success) {
          toastSuccess("File upload success");
          handleClose(false);
          redirect();
        } else {
          toastError(res.message);
        }
      });
    }
  };

  return (
    <Spin spinning={spinning}>
      <DialogContent
        dividers={false}
        style={{
          backgroundColor: Colors["lighterPurple"],
          fontSize: "0.8125rem",
          // minWidth: "40vw",
        }}
      >
        <div className="flex-spacing" style={{ margin: "1rem 0px" }}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableBody>
                    <TableRow>
                    <DropzoneArea
                      filesLimit={1}
                      acceptedFiles={[
                        ".xlsx,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                      ]}
                      onChange={(ev)=>setFile(ev)}
                      alertSnackbarProps={{
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    />
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <a
          href={`${process.env.REACT_APP_BASE_URL}/sample_files/${reason}.xlsx`}
          style={{ textDecoration: "underline" }}
          target="_blank"
        >
          Download Sample
        </a>
        <div>
          <Button
            onClick={fileUploadHandler}
            color="primary"
            variant="contained"
            className="shadow-none"
            type="submit"
            disabled={spinning}
            style={{ marginRight: "1rem" }}
            // disabled={isSubmitting || !isValid}
          >
            Submit
          </Button>
          <Button
            onClick={() => handleClose(false)}
            variant="contained"
            className="shadow-none"
            // disabled={isSubmitting || !isValid}
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Spin>
  );
};

export default LotuspayPreprocessing;
