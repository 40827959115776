import React, { useState, useRef } from "react";
import {
  DialogContent,
  Card,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableBody,
  CardContent,
} from "@material-ui/core";
import XLSX from "xlsx";

import Spin from "components/ui/Spin";
import { DropzoneArea } from "material-ui-dropzone";
import { toastError, toastSuccess, toastWarning } from "../utils";
import Colors from "shared/color";
import { lowPrioritySmBlockedupload } from "shared/api/fileUpload";
import { CSVLink } from "react-csv";

const Lowprioritysmblocked = ({ handleClose }) => {
  const [spinning, setSpinning] = useState(false);
  const [file, setfile] = useState([]);
  const [errorList, seterrorList] = useState([]);
  const errorRef = useRef();
  const fileUploadHandler = () => {
    if (file.length) {
      if (file[0].size < 500000) {
        let jsonData = null;
        const reader = new FileReader();
        reader.onload = (e) => {
          var data = e.target.result;
          let workbook = XLSX.read(data, { type: "binary" });
          jsonData = workbook.SheetNames.reduce((initial, name) => {
            const sheet = workbook.Sheets[name];
            initial[name] = XLSX.utils.sheet_to_json(sheet, {
              raw: false,
              header: 0,
              defval: null,
            });
            return initial;
          }, {});
          const param =
            jsonData &&
            Object.keys(jsonData).map((key) => {
              return jsonData[key];
            })[0];
          if (param && param.length > 0) {
            if(param.length<500){
              setSpinning(true);
              lowPrioritySmBlockedupload(param)
                .then((res) => {
                  setSpinning(false);
                  if (res && res.success) {
                    toastSuccess(res.message);
                    handleClose(false);
                  } else {
                    const { error_list } = res.data;
                    if (error_list.length > 0) {
                      toastError(res.message);
                      seterrorList(error_list);
                      errorRef.current.link.click();
                    } else {
                      toastError(res.message);
                      handleClose(false);
                    }
                  }
                })
                .catch((res) => {
                  if(res && res.hasOwnProperty('data')){

                    const { error_list } = res.data;
                    if (error_list && error_list.length > 0) {
                      toastError(data.message);
                      seterrorList(error_list);
                      errorRef.current.link.click();
                    }
                  }
                  setSpinning(false);
                });
            }else{
              toastWarning("Records should be less than 500");  
            }

          } else {
            toastError("Empty file not supported");
          }
        };
        reader.readAsBinaryString(file[0]);
      } else {
        toastError("File size must be below 500 KB");
      }
    } else {
      toastError("Please upload File");
    }
  };

  return (
    <Spin spinning={spinning}>
      <DialogContent
        dividers={false}
        style={{
          backgroundColor: Colors["lighterPurple"],
          fontSize: "0.8125rem",
        }}
      >
        <div className="flex-spacing" style={{ margin: "1rem 0px" }}>
          <div
          className="text-green-700 text-sx font-semibold"
          >
            Please add prefix # in userReferenceNo and also keep date format in
            dd-mm-YYYY
          </div>
          <p
            className="text-yellow-500 text-sx text-center mt-4"
          >
            Please keep records less than 500 to process the file.
          </p>
          <Card>
            <CardContent>
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableBody>
                    <DropzoneArea
                      filesLimit={1}
                      acceptedFiles={[
                        ".xlsx,.xls,.csv,.ods, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                      ]}
                      onChange={setfile}
                      alertSnackbarProps={{
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    />
                    <CSVLink
                      style={{ textDecoration: "none", visibility: "hidden" }}
                      data={errorList}
                      ref={errorRef}
                      filename={"lowpriority_error.csv"}
                      headers={[
                        { label: "row", key: "row" },
                        { label: "errors", key: "errors" },
                      ]}
                      target="_blank"
                    >
                      Download me
                    </CSVLink>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <a
          href={`${process.env.REACT_APP_BASE_URL}/sample_files/lowpriorityblock.xlsx`}
          style={{ textDecoration: "underline" }}
          target="_blank"
        >
          Download Sample
        </a>
        <div>
          <Button
            onClick={fileUploadHandler}
            color="primary"
            variant="contained"
            className="shadow-none"
            type="submit"
            style={{ marginRight: "1rem" }}
            disabled={spinning}
          // disabled={isSubmitting || !isValid}
          >
            Submit
          </Button>
          <Button
            onClick={() => handleClose(false)}
            variant="contained"
            className="shadow-none"
          // disabled={isSubmitting || !isValid}
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Spin>
  );
};

export default Lowprioritysmblocked;
