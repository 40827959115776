import {
  UPDATE_LOAN_PAYMENT,
  UPDATE_LOAN_MORATORIUM_PAYMENT,
  UPDATE_LOAN_DUES_WAIVER,
  UPDATE_LOAN_SETTLEMENT_PAYMENT,
  UPDATE_LOAN_SETTLEMENT,
} from "redux/actions/loan/actionTypes";

const initalState = {
  loanPayment: [],
  moratoriumPayment: null,
  duesWaiverData: null,
  settlementData: null,
  settlementPaymentData: null,
};
const loanReducer = (state = initalState, { type, payload }) => {
  switch (type) {
    case UPDATE_LOAN_PAYMENT:
      return {
        ...state,
        loanPayment: payload,
      };
    case UPDATE_LOAN_MORATORIUM_PAYMENT:
      return {
        ...state,
        moratoriumPayment: payload,
      };
    case UPDATE_LOAN_DUES_WAIVER:
      return {
        ...state,
        duesWaiverData: payload,
      };
    case UPDATE_LOAN_SETTLEMENT:
      return {
        ...state,
        settlementData: payload,
      };
    case UPDATE_LOAN_SETTLEMENT_PAYMENT:
      return {
        ...state,
        settlementPaymentData: payload,
      };

    default:
      return state;
  }
};
export default loanReducer;
