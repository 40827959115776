import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { hasRoutePermission } from "shared/utils";
const useStyles = makeStyles((theme) => ({
  button: {
    [theme.breakpoints.down("lg")]: {
      "& .MuiButton-root": {
        padding: "6px 8px",
        fontSize: "10px",
      },
    },
  },
}));

const ResponsiveButton = ({ role, route, permission,dispatch, ...rest }) => {
  const classes = useStyles();
  let allow = role["id"];

  if (allow !== 1) {
    allow = hasRoutePermission(route, role, permission);
  }

  return allow ? (
    <div className={classes.button}>
      <Button
        variant="contained"
        className="shadow-none"
        color="primary"
        {...rest}
      >
        {rest.children}
      </Button>
    </div>
  ) : null;
};

const mapStateToProps = ({ role }) => ({
  role,
});

export default connect(mapStateToProps,null)(ResponsiveButton);
