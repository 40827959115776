import React, { useState } from "react";
import { getUserDetails } from "shared/api";
import Spin from "components/ui/Spin";
import {
  DialogContent,
  Card,
  CardContent,
  Button,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableCell,
  DialogActions,
  TableRow,

} from "@material-ui/core";
import Colors from "shared/color";
import CustomInput from "components/custom/CustomInput";
import { KisstRadio } from "components/ui/KisstRadio";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import TableBodyCell from "components/table/TableBodyCell";
import { nachPresentation } from "shared/api/nach";
import { formatCurrency, toastSuccess, toastWarning } from "shared/utils";
import { add, format } from 'date-fns';
import InputField from "components/ui/form/InputField";
import { RobotFont } from "components/ui/RobotFont";

const Schema = Yup.object().shape({
  source: Yup.string().required("Source field is required!"),
  payment_request_reason: Yup.string().required(
    "payment request reason field is required!"
  ),
  source_reference_number: Yup.string().required(
    "Please enter numbers"
  ),
  payment_amount: Yup.number().required(
    "payment amount field is required!"
  ),
  nach_presentation_date: Yup.date().required(
    "Date field is required!"
  ),
});
const inputStyle = {
  height: "0.6875rem",
  minWidth: "23rem",
  color: "#898C98",
  borderRadius: "3px",
};

const PresentNach = ({ handleClose }) => {
  const [loan, setLoan] = useState({});

  const handleSearch = async (source, reference_number,setFieldValue) => {
    setLoan(null);
    getUserDetails(source, reference_number)
      .then((res) => {
        if (res.success) {
          setLoan(res["data"]["user_outstanding_details"]);
          setFieldValue('payment_amount',res.data.user_outstanding_details.total_outstanding_amount)
        }
      })
  };

  return (
    <Formik
      initialValues={{
        source: "LOAN",
        source_reference_number: "",
        payment_request_reason: "LOAN_PAYMENT",
        payment_amount: loan && loan.total_outstanding_amount,
        nach_presentation_date: format(add(new Date(), {
          hours: 24
        }), 'yyyy-MM-dd')
      }}
      validationSchema={Schema}
      onSubmit={async (values, { setSubmitting }) => {
        let data = { ...values };
        data["user_reference_number"] = loan.user_reference_number;
        try {
          let res = await nachPresentation(data);
          if (res.success) {
            toastSuccess(res.message);
            handleClose(false)

          } else {
            toastWarning(res.message);
          }
          setSubmitting(false);
        } catch (error) {
          setSubmitting(false);
        }
      }}
    >
      {({
        isValid,
        isSubmitting,
        handleSubmit,
        values,
        touched,
        handleChange,
        setFieldValue
      }) => {
        return (
          <Spin spinning={isSubmitting}>
            <Form noValidate onSubmit={handleSubmit}>
              <DialogContent
                dividers={false}
                style={{
                  backgroundColor: Colors["lighterPurple"],
                  fontSize: "0.8125rem",
                }}
              >
                <div
                  className="flex-spacing"
                  style={{ margin: "0px 0px 1rem 0px" }}
                >
                  <Card>
                    <CardContent>
                      <Grid container alignItems="center">
                        <Grid item>
                          <CustomInput
                            style={{
                              ...inputStyle,
                              minWidth: 0,
                              width: "10rem",
                              borderRadius: "0px",
                            }}
                            name={"source_reference_number"}
                            value={values.source_reference_number}
                            onChange={handleChange}
                            placeholder={values.source === "USER" ? "User Reference No." : "Loan Reference No."}
                          />
                          <Button
                            variant="contained"
                            className="shadow-none"
                            color="primary"
                            disabled={!values.source_reference_number}
                            style={{ borderRadius: "0px" }}
                            onClick={(e) => {
                              e.preventDefault();
                              handleSearch(values.source, values.source_reference_number,setFieldValue);
                            }}
                          >
                            Search
                          </Button>
                        </Grid>
                      </Grid>

                      {/* <Grid container alignItems="center">
                        <Grid item>
                          <div
                            style={{
                              display: "flex",
                              paddingTop: "1.2rem",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                paddingRight: "1.8rem",
                                color: Colors["title_light_black"],
                                fontSize: "1rem",
                              }}
                            >
                              Apply to
                            </span>
                            <KisstRadio
                              bigFont
                              values={{
                                USER: "User",
                                LOAN: "Loan",
                              }}
                              name="source"
                              value={values.source}
                              onChange={(e) => {
                                setFieldValue('source_reference_number', '');
                                setLoan(null);
                                handleChange(e)
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Grid container alignItems="center">
                        <Grid item>
                          <div
                            style={{
                              display: "flex",
                              paddingTop: "1.2rem",
                              alignItems: "center",
                            }}
                          >
                            <span
                              style={{
                                paddingRight: "1.8rem",
                                color: Colors["title_light_black"],
                                fontSize: "1rem",
                              }}
                            >
                              Payment Request Reason
                            </span>
                            <KisstRadio
                              bigFont
                              values={{
                                LOAN_PAYMENT: "Loan Payment",
                              }}
                              name="payment_request_reason"
                              value={values.payment_request_reason}
                              onChange={handleChange}
                            />
                          </div>
                        </Grid>
                      </Grid> */}
                    </CardContent>
                  </Card>
                  {loan && Object.keys(loan).length ? (
                    <Card>
                      <TableContainer>
                        <UserTable loan={loan} />
                      </TableContainer>
                    </Card>
                  ) : null}
                </div>

                {loan && Object.keys(loan).length ? (
                  <Card>
                    <TableContainer style={{ padding: "0.5625rem 1.3rem" }}>
                      <Table size="small" aria-label="simple table">
                        <TableBody>
                          <TableRow>
                            <TTableCell
                              style={{ maxWidth: "4rem", color: "#000" }}
                            >
                              Payment Amount
                            </TTableCell>
                            <TTableCell style={{ color: "#898C98" }}>
                              <Field
                                type="number"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                id="payment_amount"
                                placeholder="Please enter to Payment Amount"
                                name="payment_amount"
                                component={InputField}
                                touched={touched}
                                value={values.payment_amount}
                              />
                            </TTableCell>
                          </TableRow>
                        </TableBody>
                        <TableBody>
                          <TableRow>
                            <TTableCell
                              style={{ maxWidth: "4rem", color: "#000" }}
                            >
                              Nach Presentation Date
                            </TTableCell>
                            <TTableCell style={{ color: "#898C98" }}>
                              <Field
                                type="date"
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                id="to_date"
                                placeholder="Please enter to date"
                                name="nach_presentation_date"
                                error
                                component={InputField}
                                touched={touched}
                                value={values.nach_presentation_date}
                              />
                            </TTableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Card>
                ) : null}
              </DialogContent>
              <DialogActions style={{ margin: "1rem" }}>
                <Button
                  color="primary"
                  variant="contained"
                  type="submit"
                  disabled={isSubmitting || !isValid}
                >
                  save payment
                </Button>
              </DialogActions>
            </Form>
          </Spin>
        );
      }}
    </Formik>
  );
};

const UserTable = ({ loan }) => {
  return (
    <Table size="small" aria-label="simple table" style={{ width: "100.1%" }}>
      <TableHead >
        <TableBodyCell>
          <span style={{ color: "#000" }}>Customer Name</span>
        </TableBodyCell>
        <TableBodyCell>
          <span style={{ color: "#000" }}>Mobile No.</span>
        </TableBodyCell>

        <TableBodyCell>
          <span style={{ color: "#000" }}>Email</span>
        </TableBodyCell>
        <TableBodyCell>
          <span style={{ color: "#000" }}>Outstanding Amount</span>
        </TableBodyCell>
        
      </TableHead>
      <TableBody>
        <TableRow>
          <TableBodyCell>{loan["user_name"]}</TableBodyCell>
          <TableBodyCell>{loan["mobile_number"]}</TableBodyCell>
          <TableBodyCell>{loan["email"]}</TableBodyCell>
          <TableBodyCell><RobotFont type="medium">{formatCurrency(loan.total_outstanding_amount)}</RobotFont></TableBodyCell>
          
        </TableRow>
      </TableBody>
    </Table>
  );
};

const TTableCell = (props) => {
  return (
    <TableCell
      style={{
        border: "none",
        fontSize: "0.8125rem",
        padding: "0.5625rem 3px",
        ...props.style,
      }}
    >
      {props.children}
    </TableCell>
  );
};

export default PresentNach;
