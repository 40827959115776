import React from "react";

// Material UI
import TextField from "@material-ui/core/TextField";

// Custom input field
const InputField = ({
  field,
  helperText,
  form: { touched, errors },
  ...props
}) => {
  return (
    <>
      <TextField
        {...field}
        {...props}
        helperText={
          errors[field.name] && touched[field.name]
            ? errors[field.name]
            : helperText
        }
        error={errors[field.name] && touched[field.name]}
      />
    </>
  );
};

export default InputField;
