import React from "react";
import { Breadcrumbs, Typography, makeStyles } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Link } from "react-router-dom";
import Colors from "shared/color";

const useStyles = makeStyles((theme) => ({
  headTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    "&::after": {
      content: '" "',
      // position: absolute,
      borderRight: "1px solid #E2E5EC",
      height: " 2.1rem",
    },
  },
}));
function BreadcrumbsHeader({ text = [], links = [] }) {
  const classes = useStyles();

  return !links.length ? (
    <div
      className={classes.headTitle}
      style={{
        color: Colors["purple_200"],
        minWidth: "200px",
        textTransform: "capitalize",
      }}
    >
      <h5>{Array.isArray(text) ? text[text.length - 1] : text} </h5>
    </div>
  ) : (
    <Breadcrumbs
      separator={<NavigateNextIcon fontSize="small" />}
      aria-label="breadcrumb"
    >
      {links.map((value, index) => {
        return (
          <Link to={value} key={index}>
            <div
              className="capitalize font-medium"
              style={{ color: Colors["purple_400"] }}
            >
              {text[index]}
            </div>
          </Link>
        );
      })}
      <Typography
        className="text-opacity-50 font-medium"
        style={{ color: "#959CB6" }}
      >
        {text[text.length - 1]}
      </Typography>
    </Breadcrumbs>
  );
}

export default BreadcrumbsHeader;
