import { Divider, Grid, IconButton, Typography } from "@material-ui/core";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import { makeStyles } from "@material-ui/core/styles";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import MenuIcon from "assets/svg/sidebar/menu.svg";
import tranparentLogo from "assets/svg/transparent_logo.svg";
import onEmiLogo from "assets/svg/onemi-white.svg";
// Material UI
import clsx from "clsx";
import WithQueryParams from "components/hoc/WithQueryParams";
import React from "react";
import Colors from "shared/color";
// Component
import { DrawerSize, PERMISSIONS, SIDEBAR_LINKS } from "shared/constants";
import ListLink from "./ListLink";
import { hasRoutePermission } from "shared/utils";

// Sidebar width
const drawerWidth = 250;
const drawerCloseWidth = 96;




const AUDITOR_Role_Id = process.env.REACT_APP_NAME === "DEV" ? [65, 110] : [63, 73];

console.log(process.env.REACT_APP_NAME);

// Custom style hook
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& ::-webkit-scrollbar": {
      display: "none",
    },
  },

  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: DrawerSize["width"],
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerOpen: {
    backgroundColor: Colors["drawerColor"],
    width: DrawerSize["width"],
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    backgroundColor: Colors["drawerColor"],
    overflowX: "hidden",
    width: `${DrawerSize["closeWidth"]}px !important`,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),

    ...theme.mixins.toolbar,
  },
  content: {
    width: "81vw",
    flexGrow: 1,
    padding: theme.spacing(3),
  },

  listHover: {
    "&:hover": {
      backgroundColor: "#32374a",
    },
  },
  bgMain: {
    backgroundColor: "#2e3345",
  },
  KsIcon: {
    "& .MuiIconButton-colorPrimary": {
      color: "#fff",
    },
  },
}));

const CustomerRole_Support = process.env.REACT_APP_NAME === "DEV" ? 70 : 69;

const SideBar = (props) => {
  const { Allowed_Routes, role } = props;

  let SideBarIcons = SIDEBAR_LINKS.filter((sidebar) => {
    let found = Allowed_Routes.find((value) => value.route === sidebar.route);
    if (found) return true;
    return false;
  }).map((item) => {
    if (item.child?.length) {
      const { child, ...rest } = item;
      const filteredChild = item.child?.filter((c) => {
        if (c.permission) {
          return hasRoutePermission(c.route, role, c.permission);
        } else {
          return true;
        }
      });

      if (filteredChild.length > 0) {
        return {
          ...item,
          child: filteredChild,
        };
      } else {
        // return rest;
        return {
          ...item,
          child: filteredChild,
        };
      }
    }
    return item;
  });

  const { children, location } = props;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const isOnEmiDomain = window.location.host.split(".").includes("onemi-tech");
  const webLogo = isOnEmiDomain ? onEmiLogo : tranparentLogo;

  var subListItem = null;
  if (location.pathname.split("/").length > 2) {
    let rootRoute = location.pathname.split("/")[1];
    let subItem = SIDEBAR_LINKS.find((route, index) => {
      return route.link === `/${rootRoute}`;
    });
    subListItem =
      !!subItem &&
      subItem.child?.filter((c) => {
        if (c.permission) {
          return hasRoutePermission(c.route, role, c.permission);
        } else {
          return true;
        }
      });
  }

  const [listActiveItem, setListActiveItem] = React.useState(subListItem);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  let auditorBaseActiveList = listActiveItem;
  if(AUDITOR_Role_Id.includes(role.id)){
    SideBarIcons=[]
    auditorBaseActiveList = null
  }

  let SideBarIconsForCustomerSupport = SideBarIcons;
  if (role.id === CustomerRole_Support) {
    auditorBaseActiveList = null
    SideBarIconsForCustomerSupport = SideBarIcons.filter((item) =>
      item.text.includes("Customer Support")
    );
  }

  return (
    <div className={classes.root}>
      {/* <CssBaseline /> */}

      <Drawer
        anchor="left"
        color="primary"
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div
          style={{ minHeight: "80px" }}
          className={`${classes.toolbar} flex items-center cursor-pointer justify-center text-white`}
        >
          {open ? (
            <Grid container direction="row">
              <Grid item xs={9}>
                <div className="mt-3">
                  <img src={webLogo} width={200} />
                </div>
                {/* <Logo width={80} type="white" /> */}
              </Grid>
              <Grid
                item
                xs={3}
                style={{ textAlign: "center" }}
                className={classes.KsIcon}
              >
                <IconButton
                  onClick={handleDrawerClose}
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <ChevronLeftIcon fontSize="large" />
                </IconButton>
              </Grid>
            </Grid>
          ) : (
            <img
              src={MenuIcon}
              onClick={(e) => {
                e.stopPropagation();

                setOpen(true);
              }}
            />
          )}
        </div>

        <List>
          {SideBarIconsForCustomerSupport.map(
            ({ text, link, child, icon, route, permission }, index) => {
              if (child && !child.length) return null;
              if (
                !child &&
                permission &&
                !hasRoutePermission(route, role, permission)
              )
                return null;
              return (
                <ListLink
                  className={classes.listHover}
                  text={text}
                  key={index}
                  link={link}
                  child={child}
                  icon={icon}
                  open={open}
                  setListActiveItem={setListActiveItem}
                />
              );
            }
          )}
        </List>
      </Drawer>
      <Drawer
        variant="persistent"
        style={{ backgroundColor: "#2e3345" }}
        classes={{
          paperAnchorLeft: classes.bgMain,
        }}
        open={
          auditorBaseActiveList && typeof auditorBaseActiveList === "object"
            ? true
            : false
        }
        onClose={(_) => setListActiveItem(null)}
      >
        <div
          className={`${classes.drawer} ${classes.drawerOpen}`}
          style={{ width: drawerWidth - 2 }}
        >
          {Array.isArray(auditorBaseActiveList) && (
            <>
              <div
                className={`${classes.toolbar} cursor-pointer justify-start text-white`}
                onClick={(_) => setListActiveItem(null)}
              >
                <ChevronLeftIcon />
                <Typography>Back</Typography>
              </div>
              <Divider className="mx-4 bg-gray-700" />
              {auditorBaseActiveList.map(
                ({ text, link, child, icon }, index) => (
                  <ListLink
                    className={classes.listHover}
                    text={text}
                    key={index}
                    link={link}
                    child={child}
                    setListActiveItem={setListActiveItem}
                    icon={icon}
                    open={open}
                  />
                )
              )}
            </>
          )}
        </div>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />

        {WithQueryParams({ children, props, open })}
      </main>
    </div>
  );
};

export default (WrappedComponent, SidebarIconRoute, role) => {
  const hocComponent = ({ ...props }) => (
    <SideBar {...props} Allowed_Routes={SidebarIconRoute} role={role}>
      <WrappedComponent {...props} />
    </SideBar>
  );

  hocComponent.propTypes = {};

  return hocComponent;
};
//
