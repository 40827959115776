import { BootstrapBadge } from 'components/ui/BootstrapBadge';
import KisshtLink from 'components/ui/KisshtLink';
import React,{useMemo} from 'react';
import { NACH_API } from 'shared/api/nach';
import Colors from 'shared/color';
import { PERMISSIONS, ROUTES_VAR, ROUTE_NAME } from 'shared/constants';
import { readableFormatDate } from 'shared/utils';
import NachPresentation from '..';

const RazorpayAutoDebit = (props) =>{
  const statusOptions = {
    PAID: "Paid",
    NOT_PAID: "Not Paid",
  };
  const searchOptions = {
    payment_reference_number: "Payment Reference Number",
    user_reference_number: "User Reference Number",
    mandate_reference_number: 'Mandate Reference Number',
    partner_payment_id: "Partner Payment ID",
    razorpay_order_id: "Razorpay Order ID",
  };
    const columns = React.useMemo(() => [
        {
          Header: "Payment Reference No.",
          accessor: "payment_reference_number",
          sortable: true,
          width: 150,
          Cell: (cell) => {
            return (
              <KisshtLink
                to={`${ROUTES_VAR.payments}/${cell.value}`}
                permission={PERMISSIONS.payment.details}
                route={ROUTE_NAME.payment}
                style={{ color: Colors.purple_400 }}
              >
                {cell.value}
              </KisshtLink>
            );
          },
          filterable: false,
        },
        {
          Header: "Partner Payment ID",
          accessor: "partner_payment_id",
          sortable: false,
          filterable: false
        },
    
        {
          Header: "User Reference No.",
          accessor: "user_reference_number",
          filterable: false,
          sortable: false,
          width: 150,
          Cell: (cell) =>
            <KisshtLink
              to={`${ROUTES_VAR.users}/${cell.value}`}
              permission={PERMISSIONS.user.details}
              route={ROUTE_NAME.user}
              style={{ color: Colors.purple_400 }}
            >
              {cell.value}
            </KisshtLink>
        },
        {
            Header: "Razorpay ref No",
            accessor: "razorpay_reference_number",
            filterable: false,
            sortable: false,
        },
        {
            Header: "Razorpay Order Id",
            accessor: "razorpay_order_id",
            filterable: false,
            sortable: false,
        },
        {
            Header: "Mandate Ref No",
            accessor: "mandate_reference_number",
            filterable: false,
            sortable: false,
        },
        {
            Header: "Amount",
            accessor: "payment_amount",
            filterable: false,
            sortable: false,
        },
        {
          Header: "Payment Status",
          accessor: "payment_status",
          filterable: false,
          width: 150,
          Cell: (cell) => {
            return <BootstrapBadge text={cell.value} />;
          },
        },
        {
          Header: "Nach Status",
          accessor: "nach_status",
          filterable: false,
          width: 150,
          Cell: (cell) => {
            return <BootstrapBadge text={cell.value} />;
          },
        },
        // {
        //   Header: "Is Bounce",
        //   accessor: "is_bounce",
        //   filterable: false,
        //   Cell: (cell) => {
        //     return <BootstrapBadge text={Number(cell.value) ? "YES" : "NO"} />;
        //   },
        // },
    
        {
          Header: "Presentation Date",
          accessor: "presentation_date",
          filterable: false,
          sortable: false,
          Cell: (cell) => {
            return readableFormatDate(cell.value);
          },
        },
      ]);
    return (
        <NachPresentation 
        params={props.params}
        updateQueryParams={props.updateQueryParams}
        delteQueryParams={props.delteQueryParams} 
        history={props.history}
        title={"Razorpay Auto Debit"} 
        showSummary={false}
        columns={columns}
        url={NACH_API.autoDebitRp}
        statusOptions={statusOptions}
        searchOptions={searchOptions} />
    )
}

export default RazorpayAutoDebit;