import React from "react";

const Spin = ({ children, spinning }) => {
  return (
    <div className="relative w-full">
      {spinning && (
        <>
          <div style={{ opacity: 0.5 }}>{children}</div>
          <div className="absolute w-full h-full top-0 left-0 right-0 bottom-0 flex flex-wrap items-center justify-center">
            <div className="kissht-ripple">
              <div></div>
              <div></div>
            </div>
          </div>
        </>
      )}
      {!spinning && children}
    </div>
  );
};

export default Spin;
