import React from "react";

import "./scss/select.scss";

import { NativeSelect, withStyles, makeStyles } from "@material-ui/core";

import InputBase from "@material-ui/core/InputBase";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: " #FFFFFF",
    display: "inline-block",
    "& .MuiNativeSelect-select": {
      paddingRight: "40px !important",
      paddingTop: "6px",
      paddingBottom: "6px",
      height: "1.5rem",
    },
    "& .MuiInputBase-root": {
      "&::after": {
        content: '" "',
        display: "block",
        borderLeft: "1px solid #e2e5ec",
        position: "absolute",
        top: "3px",
        right: "2rem",
        bottom: "3px",
        opacity: 1,
      },
    },
    "& .MuiSvgIcon-root": {
      marginRight: "5px",
      opacity: "0.7",
    },
  },
}));

const BootstrapInput = withStyles((theme) => ({
  input: {
    color: " #898C98",
    borderRadius: 3,
    position: "relative",
    border: "1px solid #E2E5EC;",
    fontSize: "0.825rem",
    padding: "10px 26px 10px 12px",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    // Use the system font instead of the default Roboto font.
    fontFamily: ["poppins-medium"].join(","),
    "&:focus": {},
    "&::-webkit-scrollbar": {
      width: "22px",
      height: "10px",
      display: "block !important",
    },

    //  " &::-webkit-scrollbar-track" :{
    //     backgroundColor:" rgb(193, 193, 193)",
    //   },

    " &::-webkit-scrollbar-thumb": {
      backgroundColor: " rgb(193, 193, 193)",
    },
  },

}))(InputBase);

const CustomSelect = (props) => {
  const classes = useStyles();
  const { options, nulloption } = props;
  return (
    // <div className={[classes.root,props.container||null]} style={props.style || null}>
    <div className={classes.root} style={props.style || null}>
      <NativeSelect
        id="select-native"
        IconComponent={KeyboardArrowDownIcon}
        input={<BootstrapInput />}
        {...props}
      >
        {nulloption ? (
          <option aria-label="" value="">
            {nulloption}
          </option>
        ) : null}
        {options &&
          Object.keys(options).map((key) => {
            return (
              <option value={key} key={key}>
                {options[key]}
              </option>
            );
          })}
      </NativeSelect>
    </div>
  );
};

export default CustomSelect;
