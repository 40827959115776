import axiosInstance from "shared/axios";
const URL = {
    uri: "/api/v1/admin-action-logs",
    actionlisting: "/api/v1/admin-action-logs/listing"
}
export const userActionLogs = (payload) => {
    return axiosInstance.post(`${URL.uri}`, payload);
};

export const getUserActionLisitng = (param) => {
    let fullParam = param
        ? `${URL.actionlisting}${param}`
        : `${URL.actionlisting}`;
    return axiosInstance.get(fullParam);
};