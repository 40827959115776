import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { hasRoutePermission } from "shared/utils";

const KisshtButton = ({ role, route, permission, ...props }) => {
  const { dispatch, ...rest } = props;
  let allow = hasRoutePermission(route, role, permission);

  return allow ? (
    <Button
      variant="contained"
      className="shadow-none"
      color="primary"
      {...rest}
    >
      {rest.children}
    </Button>
  ) : null;
};

const mapStateToProps = ({ role }) => ({
  role,
});

export default connect(mapStateToProps)(KisshtButton);
