import axiosInstance from "shared/axios";
import {
  USER_API_URLS,
  PAYMENT_API,
  LOAN_API,
  SUBUSER_API,
  REFUND_API,
} from "shared/constants";
import axios from "axios";

export const sendEmailOTP = (email, ipAddress) => {
  return axiosInstance.post(USER_API_URLS.sendOTP, {
    email,
    client_ip: ipAddress || "",
    client_id: process.env.REACT_APP_CLIENT_ID,
    login_source: process.env.REACT_APP_LOGIN_SOURCE,
  });
};

export const sendLogInCred = ({ email, password, otp, ipAddress }) => {
  return axiosInstance.post(USER_API_URLS.logIn, {
    email,
    password,
    otp,
    client_id: process.env.REACT_APP_CLIENT_ID,
    login_source: process.env.REACT_APP_LOGIN_SOURCE,
    client_ip: ipAddress || "",
  });
};
export const getSubUser = (token) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}${USER_API_URLS.subUser}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};

export const tesingPanelApi = ({ method, url, data }) => {
  return axios({
    method: method,
    url: `${url}`,
    data: data,
  });
};

export const getPaymentListing = ({ selected, search, offset }) => {
  let fullSearchParam = `${PAYMENT_API.listing}?${selected}=${search}${offset ? `&offset=${offset}` : ""
    }`;
  return axiosInstance.get(fullSearchParam);
};

export const getRefundListing = (param) => {
  let fullSearchParam = `${REFUND_API.listing}${param}`;
  return axiosInstance.get(fullSearchParam);
};
export const paymentView = (search) => {
  return axiosInstance.get(`${PAYMENT_API.view}/${search}`);
};

export const viewMappingAPi = (payment_ref_no) => {
  let queryString = `${PAYMENT_API.viewMapping}?payment_reference_number=${payment_ref_no}`;
  return axiosInstance.get(queryString);
};
export const verifyPayment = (payment_ref_no, data) => {
  let queryString = `${PAYMENT_API.verify}/${payment_ref_no}`;
  return axiosInstance.post(queryString, { ...data });
};

export const applyPayment = (payment_ref_no, payload) => {
  let queryString = `${PAYMENT_API.applyPayment}/${payment_ref_no}`;
  return axiosInstance.post(queryString, payload);
};
export const getSubUserByName = ({name, extraQuery}) => {
  let queryString = `${SUBUSER_API.byName}?name=${name}${extraQuery}`;
  return axiosInstance.get(queryString);
};

export const loanOutstanding = (loan_reference_no) => {
  let queryString = `${LOAN_API.loanOutstanding}/${loan_reference_no}/outstanding-details`;
  return axiosInstance.get(queryString);
};
export const getUserDetails = (source, user_reference_no) => {
  let queryString = `${USER_API_URLS.getOutStandingDetails}/${source}/${user_reference_no}`;
  return axiosInstance.get(queryString);
};
export const loanOutstandingFlipkart = (user_reference_no) => {
  let queryString = `${LOAN_API.loanOutstanding}/${user_reference_no}/flipkart-outstanding-details`;
  return axiosInstance.get(queryString);
};
export const createPromotionalPayment = (data) => {
  return axiosInstance.post(PAYMENT_API.createPromotionalPayment, { ...data });
};
export const createPayment = (data) => {
  return axiosInstance.post(PAYMENT_API.createPayment, { ...data });
};
export const getAllRoles = () => {
  return axiosInstance.get(SUBUSER_API.roles);
};
export const getRoleDetails = (roll_id) => {
  return axiosInstance.get(`${SUBUSER_API.roles}/${roll_id}`);
};
export const getLoginRoleDetails = (roll_id, token) => {
  return axios.get(
    `${process.env.REACT_APP_BASE_URL}${SUBUSER_API.roles}/${roll_id}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
};
export const getAllPermissions = () => {
  return axiosInstance.get(SUBUSER_API.allPermissions);
};
export const lmsMapping = (payment_ref_no) => {
  return axiosInstance.get(
    `${LOAN_API.lmsMapping}?payment_reference_number=${payment_ref_no}`
  );
};
export const duesMapping = (payment_ref_no) => {
  return axiosInstance.get(
    `${LOAN_API.duesListing}?payment_reference_number=${payment_ref_no}`
  );
};
export const getUserBankAccounts = (user_reference_no) => {
  return axiosInstance.get(
    `${USER_API_URLS.users}/${user_reference_no}/bank-accounts`
  );
};
export const checkUtr = (utr_no, partner) => {
  return axiosInstance.get(
    `${PAYMENT_API.checkUtr}?utr=${utr_no}&partner=${partner}`
  );
};
export const getBankList = (bank_name) => {
  let URI = PAYMENT_API.bank;
  if (bank_name.length) {
    URI = `${PAYMENT_API.bank}?search_text=${bank_name}`;
  }
  return axiosInstance.get(URI);
};
export const getOperatingBankAccounts = () => {
  // let URI = PAYMENT_API.bank;
  // if (bank_name.length) {
  //   URI = `${PAYMENT_API.bank}?search_text=${bank_name}`;
  // }
  return axiosInstance.get(PAYMENT_API.operatingbank);
};
export const getFinancierPaymentAccounts = () => {
  // let URI = PAYMENT_API.bank;
  // if (bank_name.length) {
  //   URI = `${PAYMENT_API.bank}?search_text=${bank_name}`;
  // }
  return axiosInstance.get(PAYMENT_API.financierpaymentaccount);
};
export const createRefundEntry = (payment_ref_no, data) => {
  return axiosInstance.post(
    `${PAYMENT_API.view}/${payment_ref_no}/create-refund`,
    { ...data }
  );
};
export const updateRole = (roll_id, permission) => {
  return axiosInstance.post(`${SUBUSER_API.roles}/${roll_id}`, {
    permission,
    _method: "patch",
  });
};
export const changeToLoanPayment = (payment_ref_no) => {
  return axiosInstance.post(
    `${PAYMENT_API.view}/${payment_ref_no}/change-to-loan-payment`,
    { _method: "patch" }
  );
};
export const changePaymentReason = (payment_ref_no, data) => {
  return axiosInstance.post(
    `${PAYMENT_API.view}/${payment_ref_no}/change-payment-reason`,
    { _method: "patch", ...data }
  );
};
export const markUnpaidApi = (payment_ref_no, payload) => {
  return axiosInstance.post(
    `${PAYMENT_API.view}/${payment_ref_no}/mark-unpaid`,
    payload
  );
};
export const paymentReverseApi = (data) => {
  return axiosInstance.post(LOAN_API.paymentReverse, { ...data });
};
export const captureRazorpayPayment = (payment_ref_no) => {
  return axiosInstance.post(
    `${PAYMENT_API.view}/${payment_ref_no}/capture-razorpay-payment`
  );
};

export const ssoAuth = (param) => {
  return axiosInstance.post(USER_API_URLS.ssoAuth, {
    ...param,
    client_id: process.env.REACT_APP_CLIENT_ID,
    login_source: process.env.REACT_APP_LOGIN_SOURCE,
  });
};

export const getUniqueDescriptors = ({ selected, search, offset }) => {
  let fullSearchParam = `${PAYMENT_API.uniqueDescriptor}?${selected}=${search}${offset ? `&offset=${offset}` : ""
    }`;
  return axiosInstance.get(fullSearchParam);
};

export const createRole = (data) => {
  return axiosInstance.post(`${SUBUSER_API.roles}`, { ...data });
};