import React from "react";

// Navigation
import { NavLink } from "react-router-dom";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import "./navigation.scss";
//icons
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

function ListLink({
  icon,
  text,
  link,
  child,
  setListActiveItem,
  className,
  open,
}) {
  return child ? (
    <ListItem
      className={`${className} py-4   leading-8`}
      button
      component={NavLink}
      to={link}
      isActive={(match, location) => {
        if (match) return true;
        let currentRoute = location.pathname.split("/");

        if (link === `/${currentRoute[1]}`) return true;
        return false;
      }}
      activeClassName="active-class" //{activeLinkClass}
      onClick={(e) => {
        e.preventDefault();
        setListActiveItem(child);
      }}
    >
      {/* {icon && <ListItemIcon  style={{ minWidth: 32,transform:'scale(1.4)' }}>{icon}</ListItemIcon>} */}
      {icon && <img width={35} height={35} src={icon} style={{ paddingLeft: "15px" }} />}
      <ListItemText
        classes={{
          primary: ` ${
            open ? "visible" : "invisible"
          } pl-3   text-sm text-gray-400 lato`,
        }}
        primary={text}
      />
      <ChevronRightIcon
        className={`${open ? "visible" : "invisible"} text-white`}
      />
    </ListItem>
  ) : (
    <ListItem
      className={`${className} py-4  leading-1`}
      exact
      button
      component={NavLink}
      to={link}
      isActive={(match, location) => {
        if (match) return true;
        let currentRoute = location.pathname.split("/");

        if (link === `/${currentRoute[1]}`) return true;
        return false;
      }}
      activeClassName="active-class"
    >
      {icon && <img width={35} height={35} src={icon} style={{ paddingLeft: "15px" }} />}
      <ListItemText
        classes={{
          primary: `${
            open ? "visible" : "invisible"
          } pl-3  text-sm text-gray-400 lato`,
        }}
        primary={text}
      />
    </ListItem>
  );
}

export default ListLink;
