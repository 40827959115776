// React js default imports
import React from "react";
import { createRoot } from 'react-dom/client';
import * as serviceWorker from "./serviceWorker";
import { GoogleOAuthProvider } from "@react-oauth/google";
//Date time picker
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
// Redux
import { Provider } from "react-redux";
import store from "./redux";

import './fix.scss';
// Global scss & css file
import "assets/scss/global.scss";
// import "assets/fonts/lato/fonts.css";
import "tailwind.css";

// Toast
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Importing main app
import App from "App";

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Provider store={store}>
          <App />
          <ToastContainer />
        </Provider>
      </MuiPickersUtilsProvider>
    </GoogleOAuthProvider>
);

serviceWorker.register();
