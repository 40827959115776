import axiosInstance from "shared/axios";

export const NACH_API = {
  nachPresentation: "/api/v1/nach/present",
  fileUpload: "/api/v1/bulk-upload/file",
  listing: '/api/v1/nach',
  nachPayment:'/api/v1/nach-payment',
  summary:'/api/v1/nach-payment/summary',
  autoDebitRp: "/api/v1/nach-payment/upi-autopay",
  cancel:"/api/v1/nach/cancel",
};

export const nachPresentation = (data) => {
  return axiosInstance.post(`${NACH_API.nachPresentation}`, { ...data });
};
export const fileUpload = (data) => {
  return axiosInstance.post(`${NACH_API.fileUpload}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};
export const getNachListing = (param) => {
  let url = param ? `${NACH_API.listing}${param}` : `${NACH_API.listing}`
  return axiosInstance.get(url);
}

export const getNachPaymentListing = (nachurl,param) => {
  let url = param ? `${nachurl}${param}` : `${nachurl}`
  return axiosInstance.get(url);
}

export const getNachDetails = (nach_ref_no) => {
  let url = `${NACH_API.listing}/${nach_ref_no}`
  return axiosInstance.get(url);
}
export const getNachSummary = (_) => {
  let url = `${NACH_API.summary}`
  return axiosInstance.get(url);
}
export const cancelMandate=(data)=>{
  return axiosInstance.post(`${NACH_API.cancel}`, { ...data });
}
