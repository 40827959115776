import React, { useState, useEffect, useRef } from "react";
import ReactTable from "react-table-6";

import "react-table.css";

import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  makeStyles,
} from "@material-ui/core";

//color
import Colors from "shared/color";

//scss
import "./scss/table.scss";

//Component
import TableBodyCell from "./TableBodyCell";
import { Grid } from '@material-ui/core';
import { Pagination } from "@material-ui/lab";

// import Table from "./Table";
let headerHeight = "100px";
const useStyles = makeStyles((theme) => ({
  table: {
    // minWidth: "650px",
    position: "relative",
    "& tbody:nth-child(even)": {
      backgroundColor: Colors["lighterPurple"],
    },
    "& .MuiTableCell-head": {
      lineHeight: "1rem ",
      textTransform: "capitalize",
    },
    "& .MuiTableCell-root": {
      lineHeight: "1.2",
      position: "relative",
      "& .rt-resizer": {
        display: "none",
      },

      // maxWidth:"10.8vw",
    },
    "& .MuiTableHead-root": {
      position: "sticky !important",
      zIndex: "1",
      backgroundColor: "rgb(88,103,221)",

    }
  },
  table_container: {
    "&::-webkit-scrollbar": {
      width: "22px",
      height: "15px",
      display: "block !important",

    },
    " &::-webkit-scrollbar-track": {
      backgroundColor: Colors["lighterPurple"],
    },
    " &::-webkit-scrollbar-thumb": {
      backgroundColor: " rgb(193, 193, 193)",
    },
  },
}));

const KisshtTable = ({ showPagination, pageSize = 20, ...props }) => {
  const tableRef = useRef(null);
  console.log("getting props", props)
  const classes = useStyles();
  const [customPage, setcustomPage] = useState(props.offset || 1);
  const list = props.data;
  const columns = props.columns
  const isDefaultHeader = props.isDefaultHeader


  //    const onScroll = (event) => {
  //     props.parentCallback(tableRef.current?.scrollX);
  //     event.preventDefault();
  // }
  // useEffect(() => {
  //   window.addEventListener('scroll', onScroll);
  //   return () => {
  //       window.removeEventListener('scroll', onScroll);
  //   };
  // }, []);

  useEffect(() => {
    setcustomPage(props.offset || 1);
  }, [props.offset]);
  useEffect(() => {
    if (document.getElementById("ScreenHeader")) {
      headerHeight = document.getElementById("ScreenHeader").offsetHeight + 10;
    }
  }, [])
  const mergedStyle = { ...{ wordBreak: "break-word" }, ...props.basketNames }

  return (
    list && (
      <ReactTable
        // ref={tableRef}
        // onScroll={onScroll}
        resized={[]}
        minRows={0}
        filterable={false}
        style={(props.style) ? { ...props.style } : { border: "none", minHeight: '50px' }}
        showPaginationTop={true}
        showPaginationBottom={false}
        showPagination={showPagination}
        pageSize={pageSize}
        PaginationComponent={(props) => {
          const { pageSize, pages } = props;
          let currentPage = customPage;
          let from = currentPage == 1 ? 1 : (currentPage - 1) * pageSize + 1;
          let to = currentPage * pageSize;
          let totalData = props.totalCount
            ? props.totalCount
            : props.sortedData.length;
          return totalData && totalData > 19 ? (
            <Grid container direction="row" className="text-xs pagination-text">
              <Grid item xs={8}>
                <span
                  style={{
                    color: Colors["lightPurple"],
                    margin: "auto",
                    textAlign: "center",
                  }}
                >{`Showing result from ${from} to ${totalData < to ? totalData : to
                  } out of ${totalData}`}</span>
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'end' }}>
                <Pagination
                  onChange={(e, page) => {
                    setcustomPage(page);
                    if (!props.offset) {
                      props.onPageChange(page - 1);
                    } else {
                      props.onPageChange(page);
                    }
                  }}
                  page={currentPage}
                  count={
                    props.totalCount
                      ? Math.ceil(props.totalCount / pageSize)
                      : pages
                  }
                  hideNextButton={props.canNext}
                  hidePrevButton={props.canPrevious}
                  color="primary"
                />
              </Grid>
            </Grid>

          ) : null;
        }}
        TableComponent={(props) => {
          return (
            list.length > 0 && (
              <TableContainer style={(columns && columns.length > 12) ? { overflowX: 'auto', whiteSpace: 'nowrap' } : {}} className={classes.table_container}>
                <Table
                  size="small"
                  className={classes.table}
                  aria-label="simple table"
                >
                  {props.children}
                </Table>
              </TableContainer>
            )
          );
        }}
        TheadComponent={(props) => {
          return (
            isDefaultHeader ? <TableHead>
              <TableRow>{props.children}</TableRow>
            </TableHead> : <TableHead style={(columns && columns.length > 12) ? {} : { top: headerHeight }}>
              <TableRow>{props.children}</TableRow>
            </TableHead>
          );
        }}
        ThComponent={({ ...props }) => {
          return (
            <TableCell
              style={{
                border: `1px solid ${Colors["purlple_100"]}`,
                fontSize: "0.8125rem",
                padding: "10px",
                color: "#fff",
                whiteSpace: "break-spaces",
                fontFamily: "poppins-medium",
                ...props.style,
              }}
              className={`${props.className}`}
              onClick={props.toggleSort}
            >
              {props.children}
            </TableCell>
          );
        }}
        TrComponent={(props) => props.children}
        TbodyComponent={(props) => {
          return props.children;
        }}
        TrGroupComponent={(props) => {
          return <TableBody><TableRow>{props.children}</TableRow></TableBody>;
        }}
        TdComponent={(props) => {
          return <TableBodyCell style={mergedStyle}>{props.children}</TableBodyCell>;
        }}
        {...props}
      />
    )
  );
};

export default KisshtTable;
