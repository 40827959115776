import React from "react";
import { Button, makeStyles } from "@material-ui/core";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { hasRoutePermission } from "shared/utils";

const KisshtLink = ({ route, role, permission, ...props }) => {
  const { dispatch, ...rest } = props;
  let allow = role["id"];
  if (allow != 1) {
    allow = hasRoutePermission(route, role, permission);
  }

  return allow ? (
    <Link target="_blank" style={{ color: "#6098F1",cursor:"pointer" }} {...rest}>
      {props.children}
    </Link>
  ) : (
    props.children || null
  );
};

const mapStateToProps = ({ role }) => ({
  role,
});

export default connect(mapStateToProps)(KisshtLink);
