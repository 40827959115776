
import { sendLogInCred, getSubUser, getLoginRoleDetails, ssoAuth } from "shared/api";

import { AUTH_SUCCESS, AUTH_FAIL, AUTH_START, SIGN_OUT } from "./authTypes";
import { ROLE } from "redux/actions/role/roleTypes";
import Axios from "axios";

export const signIn = (data, isSSO = false) => async (dispatch) => {
  dispatch({
    type: AUTH_START,
  });

  if(data.ipAddress == null || data.client_ip == null){
    await Axios.get('https://api.ipify.org?format=json').then((res) => {
      data.client_ip = res.data.ip;
      if (!isSSO) {
        data.ipAddress = res.data.ip;
      }
    })
  }
  
  try {
    let res = {};
    if (isSSO) {
      res = await ssoAuth(data);
    } else {
      res = await sendLogInCred(data);
    }

    if ((isSSO && res?.data?.is_panel_user) || !isSSO) {
      const userData = await getSubUser(res.data.token);
      let user = userData.data.data.subuser;

      user["token"] = res.data.token;
      let roles = await getLoginRoleDetails(user["role_id"], res.data.token);

      localStorage.setItem("user", JSON.stringify(user));
      localStorage.setItem("role", JSON.stringify(roles["data"]["data"]));

      dispatch({
        type: ROLE,
        payload: roles["data"]["data"],
      });

      dispatch({
        type: AUTH_SUCCESS,
        payload: { user: user },
      });
    } else {
      let user = {}
      user["token"] = res.data.token;
      dispatch({
        type: AUTH_SUCCESS,
        payload: { user: user },
      });
    }


    return res.data

  } catch (err) {
    dispatch({
      type: AUTH_FAIL,
    });
    return false;
  }
};

export const signOut = (_) => async (dispatch) => {
  try {
    // localStorage.removeItem("user");
    // localStorage.removeItem("role");
    localStorage.clear();
    dispatch({
      type: SIGN_OUT,
    });
  } catch (error) {
    console.error(error);
  }
};
