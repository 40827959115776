import React from "react";
import {
  RadioGroup,
  FormControlLabel,
  Radio,
  makeStyles,
} from "@material-ui/core";
import Colors from "shared/color";

const useStyles = makeStyles((theme) => ({
  radioGroup: {
    color: "#464E5F",
    "& .Mui-checked + .MuiTypography-body1 ": {
      color: Colors["purple_800"],
    },
    "& .MuiRadio-root": {
      padding: "0px 3px 0px 0px",
    },
    "& .MuiFormControlLabel-root": {
      marginLeft: "0px",
    },
    "& .MuiSvgIcon-fontSizeSmall": {
      fontSize: "1rem",
    },
  },
  smallLebel: {
    "& .MuiTypography-root": {
      fontSize: "1rem",
    },
  },
}));

export const KisstRadio = ({
  value,
  values,
  bigFont = false,
  onChange,
  ...props
}) => {
  const classes = useStyles();
  return (
    <RadioGroup
      className={classes.radioGroup}
      row
      value={value}
      onChange={onChange}
      size="small"
      {...props}
    >
      {Object.keys(values).length &&
        Object.keys(values).map(function (key) {
          return (
            <FormControlLabel
              value={key}
              key={key}
              control={<Radio color="primary" size={props.size ||"small" } />}
              label={values[key]}
              component={"div"}
              className={!bigFont ? classes.smallLebel:null}
            />
          );
        })}
    </RadioGroup>
  );
};
