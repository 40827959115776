import {
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  Table,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import Spin from "components/ui/Spin";
import { DropzoneArea } from "material-ui-dropzone";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { fileUpload } from "shared/api/nach";
import Colors from "shared/color";
import { toastError, toastSuccess } from "shared/utils";

const samples = {
  PAYMENTS: "payment",
  LOANS: "loan",
  TRANSACTIONS: "txn",
};
const KisshtFileUpload = ({ handleClose, module, reason, data, ...props }) => {
  const [spinning, setSpinning] = useState(false);
  const [file, setFile] = useState([]);

  const redirect = () => {
    props.history.push(`/uploads?selected=${reason}`);
  };

  const fileUploadHandler = () => {
    if (file.length) {
      var formData = new FormData();
      formData.append("file", file[0]);
      formData.append("reason", reason);
      formData.append("module", module);
      setSpinning(true);
      fileUpload(formData).then((res) => {
        setSpinning(false);
        if (res.success) {
          toastSuccess("File upload success");
          handleClose(true);
          // if (module === "TRANSACTIONS" || module === "PAYMENTS") {
            redirect();
          // }
        } else {
          toastError(res.message);
        }
      }).catch( () => {
        setSpinning(false)
      })
    }else{
      toastError("Please upload file");
    }
  };

  return (
    <Spin spinning={spinning}>
      <DialogContent
        dividers={false}
        style={{
          backgroundColor: Colors["lighterPurple"],
          fontSize: "0.8125rem",
        }}
      >
        <div className="flex-spacing" style={{ margin: "1rem 0px" }}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableBody>
                    <DropzoneArea
                      filesLimit={1}
                      acceptedFiles={[
                        ".xlsx,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                      ]}
                      onChange={setFile}
                      alertSnackbarProps={{
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <a
          href={
            props.samplefilePath
              ? props.samplefilePath
              : `${process.env.REACT_APP_UPLOAD}/${samples[module]}-service/${reason}.xlsx`
          }
          style={{ textDecoration: "underline" }}
          target="_blank"
        >
          Download Sample
        </a>
        <div>
          <Button
            onClick={fileUploadHandler}
            color="primary"
            variant="contained"
            className="shadow-none"
            type="submit"
            disabled={spinning}
            style={{ marginRight: "1rem" }}
          >
            Submit
          </Button>
          <Button
            onClick={() => handleClose(false)}
            variant="contained"
            className="shadow-none"
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Spin>
  );
};

KisshtFileUpload.propTypes = {
  handleClose: PropTypes.func,
  moduleName: PropTypes.string,
  reason: PropTypes.string,
  samplefilePath: PropTypes.string,
};

export default KisshtFileUpload;
