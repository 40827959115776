import React from "react";

// Navigation
import { NavLink } from "react-router-dom";

// Material UI
import Button from "@material-ui/core/Button";
import BackIcon from "@material-ui/icons/KeyboardBackspace";

// Image
import MainBG from "assets/images/bg.png";

const PageNotFound = (props) => {
  
  return (
    <>
      <div
        className="min-h-screen flex flex-col flex-wrap justify-center items-center text-white"
        style={{ backgroundImage: `url(${MainBG})` }}
      >
        <div className="">
          <h1 className="mr-2 flex items-center">
            <span className="font-semibold text-2xl mr-1">404</span> |
            <span className="text-2xl ml-1">Page Not Found</span>
          </h1>
        </div>
        <Button
          component={NavLink}
          to="/"
          className="capitalize"
          color="inherit"
        >
          <BackIcon className="text-sm mr-1" /> Go to home
        </Button>
      </div>
    </>
  );
};

export default PageNotFound;
