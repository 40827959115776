import React, { useState } from "react";
import {
  DialogContent,
  Card,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  CardContent,
} from "@material-ui/core";

import Spin from "components/ui/Spin";
import { DropzoneArea } from "material-ui-dropzone";
import { softDeleteUpload } from "shared/api/fileUpload";
import { toastError, toastSuccess } from "../utils";
import Colors from "shared/color";

const TCell = (props) => {
  return (
    <TableCell
      style={{
        border: "none",
        fontSize: "0.8125rem",
        padding: " 3px",
        ...props.style,
      }}
    >
      {props.children}
    </TableCell>
  );
};

const SoftDelete = ({ handleClose }) => {
  const [spinning, setSpinning] = useState(false);
  const [file, setFile] = useState([]);

  const fileUploadHandler = () => {
    if (file.length) {
      var formData = new FormData();
      formData.append("soft_delete", file[0]);
      setSpinning(true);
      softDeleteUpload(formData).then((res) => {
        setSpinning(false);
        if (res.success) {
          toastSuccess(res.message);
          handleClose(false);
        } else {
          toastError(res.message);
        }
      });
    }
  };

  return (
    <Spin spinning={spinning}>
      <DialogContent
        dividers={false}
        style={{
          backgroundColor: Colors["lighterPurple"],
          fontSize: "0.8125rem",
        }}
      >
        <div className="flex-spacing" style={{ margin: "1rem 0px" }}>
          <Card>
            <CardContent>
              <TableContainer>
                <Table size="small" aria-label="simple table">
                  <TableBody>
                    <DropzoneArea
                      filesLimit={1}
                      acceptedFiles={[
                        ".xlsx,.xls,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                      ]}
                      onChange={setFile}
                      alertSnackbarProps={{
                        anchorOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                      }}
                    />
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </div>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <a
          href={`${process.env.REACT_APP_BASE_URL}/sample_files/sample_soft_delete.xls`}
          style={{ textDecoration: "underline" }}
          target="_blank"
        >
          Download Sample
        </a>
        <div>
          <Button
            onClick={fileUploadHandler}
            color="primary"
            variant="contained"
            className="shadow-none"
            type="submit"
            style={{ marginRight: "1rem" }}
            disabled={spinning}
          >
            Submit
          </Button>
          <Button
            onClick={() => handleClose(false)}
            variant="contained"
            className="shadow-none"
            // disabled={isSubmitting || !isValid}
          >
            Close
          </Button>
        </div>
      </DialogActions>
    </Spin>
  );
};

export default SoftDelete;
