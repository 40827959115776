import { AUTH_START, AUTH_END, AUTH_FAIL, AUTH_SUCCESS, SIGN_OUT, CLEAN_UP } from "redux/actions/auth/authTypes";

const initState = {
  user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user"))  : {},
  error: null,
  loading: false
};

const authReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case AUTH_START:
      return {
        ...state,
        loading: true
      };

    case AUTH_END:
      return {
        ...state,
        loading: false
      };

    case AUTH_FAIL:
      return {
        ...state,
        error: payload
      };

    case AUTH_SUCCESS:
      return {
        ...state,
        user: payload.user,
        error: false
      };

    case SIGN_OUT:
      return {
        ...state,
        user: {},
        error: false
      };

    case CLEAN_UP:
      return {
        ...state,
        error: null,
        loading: false
      };

    default:
      return state;
  }
};

export default authReducer;
