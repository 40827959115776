import React from "react";

import { queryStringParsar } from "shared/utils";

const WithQueryParams = ({ children, props, open }) => {
  const params = queryStringParsar(props.location.search);

  //create new query string and redirect the route with query
  const updateQueryParams = (query) => {
    let serachUrl = "";
    for (const key in query) {
      if (query.hasOwnProperty(key)) {
        if (query[key] && query[key].length > 0) {
          serachUrl += `${key}=${query[key]}&`;
        }
      }
    }
    props.history.push(`?${serachUrl}`);
  };
  const delteQueryParams = async (_) => {
    let params = new URLSearchParams(props.location.search);
    props.history.replace(props.history.pathname);
  };

  return React.cloneElement(children, {
    props,
    open: open,
    params,
    updateQueryParams,
    delteQueryParams,
  });
};

export default WithQueryParams;
