import {
  RESET_TRANSACTION_DATA,
  TRANSACTION_DISBURSAL_INFO,
  UPDATE_TRANASACTION_DATA,
  UPDATE_TRANASACTION_LOADING,
} from "redux/actions/transaction";

const initialState = {
  transactionData: null,
  disbursalInfo: null,
  reload: true,
};

const transactionReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_TRANASACTION_DATA:
      const { tabname, data } = payload;
      const updatedData = { [`${tabname}`]: data };
      return {
        ...state,
        transactionData: { ...state.transactionData, ...updatedData },
      };
    case UPDATE_TRANASACTION_LOADING:
      return {
        ...state,
        reload: payload,
      };
    case RESET_TRANSACTION_DATA:
      return {
        ...state,
        ...initialState,
      };
    case TRANSACTION_DISBURSAL_INFO:
      return {
        ...state,
        disbursalInfo: payload,
      };


    default:
      return { ...state };
  }
};
export default transactionReducer;
