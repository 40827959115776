export const UPDATE_TRANASACTION_DATA = "UPDATE_TRANASACTION_DATA";
export const UPDATE_TRANASACTION_LOADING = "UPDATE_TRANASACTION_LOADING";
export const RESET_TRANSACTION_DATA = "RESET_TRANSACTION_DATA";
export const TRANSACTION_DISBURSAL_INFO = "TRANSACTION_DISBURSAL_INFO";

export const updateTransactionData = (payload) => async (dispatch) => {
  dispatch({
    type: UPDATE_TRANASACTION_DATA,
    payload,
  });
};

export const updateTransactionloading = (payload) => async (dispatch) => {
  dispatch({
    type: UPDATE_TRANASACTION_LOADING,
    payload,
  });
};

export const resetTransactionData = (_) => async (dispatch) => {
  dispatch({
    type: RESET_TRANSACTION_DATA,
  });
};

export const updateTransDisbursalInfo = (payload) => async (dispatch) => {
  dispatch({
    type: TRANSACTION_DISBURSAL_INFO,
    payload
  });
};
