import React from "react";

// App routes
import Routes from "routes";

// API handle
import withAxiosWrapper from "shared/axios/withAxiosWrapper";

// Material UI
import { ThemeProvider, createTheme } from "@material-ui/core/styles";
import BlueGrey from "@material-ui/core/colors/blue";
import Green from "@material-ui/core/colors/green";
import Red from "@material-ui/core/colors/red";
import Colors from "shared/color";
import './fix.scss';
let App = () => {
  // Custom theme
  const theme = createTheme({
    palette: {
      primary: {
        light: BlueGrey[100],
        main: "rgb(88,103,221)",
        dark: BlueGrey[700],
      },
      secondary: {
        light: Red[300],
        main: Red[500],
        dark: Red[700],
      },
      success: {
        light: Green[300],
        main: Green[500],
        dark: Green[700],
      },
      error: {
        light: Red[300],
        main: Red[500],
        dark: Red[700],
      },

      type: "light",
    },
    typography: {
      color: "#fff",
      button: {
        textTransform: "capitalize",
        fontFamily: "poppins-semibold",
        fontSize: "0.8125rem",
      },

      fontFamily: "poppins-medium",
      // fontSize:"0.8125rem",
      // color:"red"
    },
    overrides: {
      MuiPaper: {
        elevation1: {
          boxShadow: `0px 3px 6px ${Colors["LightPurpleShadow"]}`,
        },
        rounded: {
          borderRadius: "5px",
        },
      },
      MuiCardContent: {
        root: {
          padding: "1.2rem",
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: Colors["purlple_100"],
        },
      },
      MuiButton: {
        contained: {
          // height:"40px",
          boxShadow: "none",
        },
      },
      MuiTableContainer: {
        root: {
          overflowX: "none",
        },
      },
      MuiTypography: {
        root: {
          // fontSize: "0.8125rem",
          textTransform: "capitalize",
        }
      },

      // MuiCssBaseline: {
      //   '@global': {
      //     '@font-face': ["poppins-medium"],
      //   },
      // },
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <Routes />
    </ThemeProvider>
  );
};

App = withAxiosWrapper(App);

export default App;
