import { combineReducers } from "redux";

import authReducer from "./auth";
import roleReducer from "./role";
import loanReducer from "./loan/loan";
import transactionReducer from "./transaction/transactionreducer";

export default combineReducers({
  auth: authReducer,
  role: roleReducer,
  loan: loanReducer,
  transaction: transactionReducer,
});
