import React from "react";
export const color = {
  RED: "#FF4961",
  GREEN: "#0ABB87",
  ORANGE: "#FFB82F",
  PURPLE: "#5867DD",
  BLUE: "#1E9FF2",
};

const colorCheme = {
  INACTIVE: color.RED,
  ACTIVE: color.GREEN,
  CANCELLED: color.RED,
  Cancelled: color.RED,
  REVERSED: color.RED,
  NOT_PAID: color.RED,
  FAILURE: color.RED,
  FAILED: color.RED,
  Failed: color.RED,
  FAILED_INTERRUPTED: color.RED,
  No: color.RED,
  NO: color.RED,
  REFUNDED: color.RED,
  Refund: color.RED,
  REJECT: color.RED,
  BOUNCE: color.RED,
  PAID: color.GREEN,
  Paid: color.GREEN,
  captured: color.GREEN,
  CREATED: color.GREEN,
  SUCCESS: color.GREEN,
  success: color.GREEN,
  Yes: color.GREEN,
  YES: color.GREEN,
  Edit: color.GREEN,
  CHEQUE_PROCESSED: color.GREEN,
  RESOLVED: color.GREEN,
  Received: color.GREEN,
  Approved: color.GREEN,
  APPROVED: color.GREEN,
  Verified: color.GREEN,
  COMPLETED: color.GREEN,
  NACH_REQUESTED: color.ORANGE,
  "Not Verified": color.ORANGE,
  "Not Checked": color.ORANGE,
  "IN-PROGRESS": color.ORANGE,
  UPLOADED: color.ORANGE,
  Ongoing: color.ORANGE,
  PROCESSING: color.ORANGE,
  CLOSED: color.RED,
  ONGOING: color.PURPLE,
  DELIQUENT: color.RED,
  PARTIAL_PAID: color.ORANGE,
  INITIATE: color.PURPLE,
  initiate: color.PURPLE,
  ASSIGNED_TO_FINANCIER: color.ORANGE,
  COND_APPROVED: color.ORANGE,
  FINAL_APPROVED: color.GREEN,
  APPROVE: color.GREEN,
  CUST_ABORT: color.PURPLE,
  DENIED_1: color.RED,
  DENIED_2: color.RED,
  NOT_SEEN: color.RED,
  TIMED_OUT: color.RED,
  REWORK: color.RED,
  TIMEDOUT: color.RED,
  PENDING: color.ORANGE,
  "REVERSAL TIMEDOUT": color.ORANGE,
  SUBMITTED_TO_BANK: color.GREEN,
  REJECTED: color.RED,
  RETRY: color.BLUE,
  AUTHORISED: color.PURPLE,
  READY_TO_SUBMIT: color.PURPLE,
  NACH_PROCESSED: color.GREEN,
  ARCHIVED: 'grey',
  DISBURSAL_STARTED: color.ORANGE,
  FORMED: color.PURPLE,
  READY_FOR_DISBURSAL: color.BLUE,
  DISBURSED: color.GREEN,
  KYC_COMPLETED: color.GREEN,
  BANK_ADDED: color.BLUE,
  KYC_INCOMPLETE: color.RED,
  TNC_ACCEPTED: color.PURPLE,
  DISBURSAL_STARTED: color.ORANGE,
  DISBURSED: color.GREEN,
  JOINED: color.GREEN,
  EXITED: color.RED,
  HOLD: 'grey',
  DISCARDED: color.RED,
  INPROCESS: color.BLUE,
  "In Process": color.BLUE,
  "PRE_PROCESS": color.BLUE,
  INITIATED: color.BLUE,
  TERMINATED: color.RED,
  SUSPENDED: color.ORANGE,
  WHITELIST_ONLY: color.ORANGE,
  "COOLING OFF CANCELLED": color.ORANGE,
  PENDING_CL: color.ORANGE,
  PENDING_UW: color.ORANGE,
  DOC_PENDING: color.BLUE,

  DEBIT: color.RED,
  CREDIT: color.GREEN,
  FAST_LOAN: color.RED,
  FULL_PAYMENT: color.PURPLE,
  CC_EMI: color.GREEN,
  PERSONAL_LOAN: color.PURPLE,
  VOUCHER: color.PURPLE,
  UPI_FULL_PAYMENT: color.PURPLE,
  BBPS_TRANSACTION: color.PURPLE,
  LATERPAY_SCAN: color.PURPLE,
  LATERPAY_BBPS: color.PURPLE,
  LATERPAY_SELF_BANK_TRANSFER: color.PURPLE,
  LATERPAY_BENE_UPI_TRANSFER: color.PURPLE,
  LATERPAY_CREDIT_CARD: color.PURPLE,
  OFFBOOK_BANK_TRANSFER: color.PURPLE,
  ACCEPTED: color.GREEN,
  PUBLISH: color.GREEN,
  DRAFT: color.ORANGE,

};

export const BootstrapBadge = ({
  text,
  color,
  backgroundColor = false,
  fontSize,
}) => {
  text =
    typeof text === "number" || typeof text === "boolean"
      ? text
        ? "YES"
        : "NO"
      : text;
  return (
    <span
      style={{
        color: colorCheme[text] ? color || '#fff' : color || '#000',
        backgroundColor: backgroundColor || colorCheme[text],
        padding: "4px 7px",
        fontSize: fontSize || "0.6875rem",
        borderRadius: "3px",
        fontFamily: "poppins-medium",
      }}
    >
      {text}
    </span>
  );
};
