import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

// Components
import Spin from "components/ui/Spin";
import sideDrawer from "components/navigation/SideDrawer";
import PrivateRoute from "components/navigation/PrivateRouter";

// Routes
import LIST_ROUTES from "./routes";

// redux
import { connect } from "react-redux";

// Page
import PageNotFound from "pages/PageNotFound";
import PermissionDenied from "pages/PageNotFound/PermissionDenied";
import { PERMISSIONS, ROUTES_VAR } from "shared/constants";
import { hasRoutePermission } from "shared/utils";

const SuspenseLoader = () => (
  <Spin spinning>
    <div className="min-h-screen" />
  </Spin>
);

const exceptionRoutes = (routes) => {
  if (
    routes.path !== ROUTES_VAR.testing ||
    (routes.path === ROUTES_VAR.testing && ["DEV", "QA"].includes(process.env.REACT_APP_NAME))
  ) {
    return true;
  }
  return false;
};

const Routes = ({ user, role }) => {

  let loggedInToken = user && user["token"];
  let Allowed_Routes = LIST_ROUTES.filter(exceptionRoutes);
  let SidebarIconRoute = Allowed_Routes;

  //filter routes based on permission if user is not admin
  if (Object.keys(role).length && role["id"] !== 1) {
    SidebarIconRoute = [];
    if (role["permission"] && Object.keys(role["permission"]).length) {
      Allowed_Routes = Allowed_Routes.map((routes) => {
        if (
          routes.permission === PERMISSIONS.all ||
          hasRoutePermission(routes.route, role, routes.permission)
        ) {
          SidebarIconRoute.push(routes);
          return routes;
        }
        return { ...routes, component: PermissionDenied, isSideDrawer: false };
      });
    } else {
      SidebarIconRoute = [LIST_ROUTES[0], LIST_ROUTES[1]];
      Allowed_Routes = [LIST_ROUTES[0], LIST_ROUTES[1]];
    }
  }

  const renderRoutes = Allowed_Routes.map(
    ({ component, isPrivate, isSideDrawer, ...rest }, index) => {
      return isPrivate ? (
        <PrivateRoute
          key={index}
          {...rest}
          loggedInToken={loggedInToken}
          comp={
            isSideDrawer ? sideDrawer(component, SidebarIconRoute, role) : component
          }
        />
      ) : (
        <Route key={index} {...rest} component={component} />
      );
    }
  );

  return (
    <Router>
      <Suspense fallback={<SuspenseLoader />}>
        <Switch>
          {renderRoutes}
          <Route exact component={PageNotFound} />
        </Switch>
      </Suspense>
    </Router>
  );
};

const mapStateToProps = ({ auth, role }) => ({
  user: auth.user,
  role,
});

export default connect(mapStateToProps, null)(Routes);
