import React from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  IconButton,
  makeStyles,
  Paper,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Draggable from "react-draggable";

//
const useStyle = makeStyles((theme) => ({
  content: {
    "& .MuiDialogContent-dividers": {
      padding: 2,
    },
  },
}));
function PaperComponent(props) {
  return (
    <Draggable
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
    >
      <Paper {...props} />
    </Draggable>
  );
}

export const KisshtDialog = ({
  open = true,
  scroll = "paper",
  handleClose,
  data,
  maxWidth,
  fullWidth,
  action=null,
  title="",
  ...props
}) => {
  const classes = useStyle();
  return (
    <Dialog
      open={open}
      maxWidth={maxWidth || "md"}
      fullWidth={fullWidth || true}
      onClose={() => handleClose(false)}
      scroll={scroll}
      // disableBackdropClick={true}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      {...props}
      className={classes.content}
      PaperComponent={PaperComponent}
    >
      <DialogTitle
        style={{ cursor: "move" }}
        id="draggable-dialog-title"
      >
        <Grid container>
          <Grid item xs={6}>
            {title}
          </Grid>
          <Grid item xs={4} className={"text-right"}>
            {action}
          </Grid>
          <Grid item xs={2} className={"text-right"}>
            <IconButton aria-label="close" onClick={() => handleClose(false)}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
      </DialogTitle>
        {props.children}
    </Dialog>
  );
};
