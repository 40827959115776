import React from "react";
import { TableCell } from "@material-ui/core";
const TableBodyCell = ({ style, ...props }) => {
  return (
    <TableCell
      style={{
        border: "1px solid #EBEDF2",
        padding: "6px 7px",
        fontSize: "0.8125rem",
        color: "#515458",
        // overflowWrap: "anywhere",
        ...style,
      }}
      {...props}
    />
  );
};

export default TableBodyCell;
