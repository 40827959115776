import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import RefreshIcon from '@material-ui/icons/Refresh';
import Header from "components/navigation/Header";
import { Toolbar, Grid, List } from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { GlobalHeader } from "components/navigation/components/GlobalHeader";
import BreadcrumbsHeader from "components/navigation/BreadcrumbsHeader";
import ResponsiveButton from "components/ui/ResponsiveButton";
import { ROUTE_NAME, PERMISSIONS, ROUTES_VAR } from "shared/constants";
import Colors from "shared/color";
import { KisshtDialog } from "components/custom/KisshtDialog";
import PresentNach from "./dialogs/PresentNach";
import LotuspayPreprocessing from "./dialogs/LotuspayPreprocessing";
import ResponsiveMenu from "components/ui/ResponsiveMenu";
import { Field, Form, Formik } from "formik";
import CustomSelect from "components/custom/CustomSelect";
import InputField from "components/ui/form/InputField";
import { getNachListing, getNachPaymentListing } from 'shared/api/nach';
import { Card } from '@material-ui/core';
import { CardContent } from '@material-ui/core';
import { Button } from '@material-ui/core';
import KisshtLink from "components/ui/KisshtLink";
import { RobotFont } from '../../components/ui/RobotFont';
import { BootstrapBadge } from "components/ui/BootstrapBadge";
import { formatCurrency, readableFormatDate } from "shared/utils";
import KisshtTable from "components/table/KisshtTable";
import NachSummary from "./components/NachSummary";
import Spin from "components/ui/Spin";
import KisshtFileUpload from "components/ui/KisshtFileUpload";
import { CloudUploadRounded, ListAlt, Search } from "@material-ui/icons";


const items = [
  {
    reason: 'LOTUSPAY_NACH_PREPROCESSING',
    name: 'Upload Lotuspay Preprocessing',
    module: "PAYMENTS",
  },
  {
    reason: 'CANCEL_MANDATE',
    name: 'Cancel Registered NACH',
    module: "PAYMENTS",
  },
];
const NachSamplefilePath = process.env.REACT_APP_BASE_URL + "/sample_files/CANCEL_MANDATE.xlsx";
const LotuspayNachSamplefilePath = process.env.REACT_APP_BASE_URL + "/sample_files/LOTUSPAY_NACH_PREPROCESSING.xlsx";

let offset = 1;
const NachPresentation = (props) => {

  const { selected, status, search } = props.params;
  const { showSummary, title, columns, statusOptions, searchOptions } = props;
  const [presentNach, setPresentNach] = useState(false);
  const selectedItem = useRef('');
  const [list, setlist] = useState(null);
  const [loading, setloading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [model, setmodel] = useState(false);

  const filterSchema = Yup.object().shape({
    status: Yup.string(),
    selected: Yup.string(),
    search: Yup.string('').when('selected', (selected, schema) => {
      if (selected) {
        return schema.required('Please enter search')
      } else {
        return schema
      }
    })
  })
  const handleQuickActionSelection = (index) => {
    if (index >= 0) {
      selectedItem.current = items[index];
      setmodel(true)
    }
  }



  const fetchNachPaymentListing = async(query = null) => {
    setloading(true);
    let param = query || getParam(props.params);
    getNachPaymentListing(props.url,param).then(res => {
      setloading(false);
      if (res && res.hasOwnProperty('data') && res.data.hasOwnProperty('nach_payments')) {
        const { nach_payments } = res.data;
        if (nach_payments) setlist(nach_payments);
      }
    }, err => {
      setloading(false);
    })
  }

  const handleFilterSubmit = async (values) => {
    const { selected, status, search } = values;
    await props.updateQueryParams({ selected, status, search })
    let querys = getParam(values);
    await fetchNachPaymentListing(querys);
  }

  const getParam = ({ selected, source, status, search }) => {
    let query = "?";
    if (status) {
      query += `status=${status}`;
    }
    if (selected) {
      query += `${status?'&':''}${selected}=${search}`;
    }
    query += `&offset=${offset - 1}`;
    return query;
  }

  const onPageChange = (value) => {
    offset = value;
    fetchNachPaymentListing();
  };

  useEffect(() => {
    offset = 1;
    fetchNachPaymentListing();
  }, [refresh])
  const resetHandler = async(resetForm) =>{
    await props.delteQueryParams();
    await resetForm();
    offset=1;
    await setRefresh(!refresh);
  }

  return (
    <>
      <Header open={props.open}>
        <Toolbar
          style={{
            backgroundColor: Colors["AppBarBGPurple"],
            minHeight: "65px",
          }}
        >
          <GlobalHeader />
        </Toolbar>
        <Toolbar>
          <Formik initialValues={{
            status: status || '',
            selected: selected || '',
            search: search || ''
          }}
            validationSchema={filterSchema}
            onSubmit={handleFilterSubmit}
          >
            {({ values, touched, handleChange, handleReset }) => <Form>

              <Grid container alignItems="center">
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item>
                      <BreadcrumbsHeader text={title} />
                    </Grid>
                  <Grid item>
                    <CustomSelect
                      onChange={handleChange}
                      name="status"
                      nulloption="Select Status"
                      value={values.status}
                      options={statusOptions}
                    />
                  </Grid>
                  <Grid item>
                    <CustomSelect
                      onChange={handleChange}
                      name="selected"
                      nulloption="Search by"
                      value={values.selected}
                      options={searchOptions}
                    />
                  </Grid>
                  <Grid item>
                    <Field
                      variant="outlined"
                      size="small"
                      InputLabelProps={{ shrink: true }}
                      id="search"
                      placeholder="Search"
                      name="search"
                      component={InputField}
                      touched={touched}
                      value={values.search}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      startIcon={<Search />}
                      variant="contained"
                      className="shadow-none"
                      color="primary"
                      size="medium"
                      type="submit"
                    >
                      Search
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      className="shadow-none"
                      color="primary"
                      size="medium"
                      onClick={()=>{resetHandler(handleReset)}}
                      startIcon={<RefreshIcon />}
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              </Grid>
            </Form>
            }
          </Formik>
        </Toolbar>
        <Toolbar>
          <Grid container alignItems="center">
            <Grid item xs={12}>
              <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="flex-end"
              >
                <Grid item>
                  <ResponsiveMenu
                    buttonText="uploads"
                    onSelect={(index) => {
                      handleQuickActionSelection(index);
                    }}
                    endIcon={<ArrowDropDownIcon />}
                    menuItems={[
                      {
                        route: ROUTE_NAME.nach,
                        permission: PERMISSIONS.nach.present,
                        text: "Upload Lotuspay Preprocessing",
                      },
                      {
                        route: ROUTE_NAME.nach,
                        permission: PERMISSIONS.nach.present,
                        text: "Cancel Registered NACH",
                      }
                    ]}
                  />
                </Grid>
                <Grid item>
                  <ResponsiveButton
                    startIcon={<ListAlt />}
                    onClick={(_) => setPresentNach(true)}
                    route={ROUTE_NAME.nach}
                    permission={PERMISSIONS.nach.present}
                  >
                    Present NACH Payments
                  </ResponsiveButton>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Header>
      <div style={{ marginTop: "8rem" }}>
        <Spin spinning={loading}>
        {showSummary && (<NachSummary />)}
        <Card
          style={{
            boxShadow: `0px 3px 6px ${Colors["LightPurpleShadow"]}`,
            marginTop: 10,
          }}
        >
          <CardContent style={{ height: "50px" }}>
            <Grid container className="grid-vertical-align-center">
              <Grid item xs={12}>
                <p
                  className="text-xs"
                  style={{
                    color: Colors["lightPurple"],
                    margin: "auto",
                  }}
                ></p>
              </Grid>
            </Grid>
          </CardContent>
          <CardContent>
            <KisshtTable
              loading={loading}
              columns={columns}
              data={list}
              onPageChange={onPageChange}
              showPagination={true}
              offset={offset}
              totalCount={(list && list.length > 0) ? 1000 : (offset * 20)}
            />
          </CardContent>
        </Card>
        </Spin>
      </div>
      <KisshtDialog
        title="Present NACH Payments"
        open={presentNach}
        handleClose={setPresentNach}
      >
        <PresentNach handleClose={setPresentNach} props={props} />
      </KisshtDialog>
      <KisshtDialog
        maxWidth="sm"
        title={selectedItem.current.name}
        open={model}
        handleClose={setmodel}
      >
        <KisshtFileUpload
            module={selectedItem.current.module}
            reason={selectedItem.current.reason}
            handleClose={setmodel}
            {...props}
            samplefilePath={
              selectedItem.current.reason === "CANCEL_MANDATE" 
              ? NachSamplefilePath 
              : selectedItem.current.reason === "LOTUSPAY_NACH_PREPROCESSING"
              ? LotuspayNachSamplefilePath 
              : null}
          />
      </KisshtDialog>
    </>
  );
};

export default NachPresentation;
