var Colors={
    white:"#fff",
    lightPurple:"#9297B8",
    lighterPurple:"#F7F8FA",
    LightPurpleShadow:"#523F690D",
    drawerColor:"#1A1A27",
    AppBarBGPurple:'#F8F9FA',
    purlple_100:"#EBEDF2",
    purple_200:"#959CB6",
    purple_400:"#6098F1",
    purple_800:"#5867DD",
    title_light_black:"#464E5F",
    red:'#f44336',
    green:'#4caf50'

}

export default Colors